<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                src="@/assets/images/pages/login.png"
                alt="login"
                class="mx-auto"
              />
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <div>
                  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                    <form
                      @submit.prevent="handleSubmit(onClickLogin)"
                      autocomplete="off"
                    >
                      <ValidationProvider
                        vid="email"
                        name="email"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <vs-input
                          name="email"
                          icon-no-border
                          icon="icon icon-user"
                          icon-pack="feather"
                          label-placeholder="Username"
                          v-model="email"
                          class="w-full"
                        />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                        vid="password"
                        name="password"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <vs-input
                          type="password"
                          name="password"
                          icon-no-border
                          icon="icon icon-lock"
                          icon-pack="feather"
                          label-placeholder="Password"
                          v-model="password"
                          class="w-full mt-6"
                        />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <div class="flex flex-wrap justify-between my-5">
                        <!--                      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox>-->
                        <!--                      <router-link to="">Forgot Password?</router-link>-->
                      </div>
                      <!--                  <vs-button  type="border">Register</vs-button>-->
                      <vs-button
                        id="login"
                        button="submit"
                        class="float-right mb-8"
                        >Login</vs-button
                      >
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ErrorMessages from '@/mixins/ErrorMessages';

export default {
  data() {
    return {
      email: '',
      password: '',
      checkbox_remember_me: false,
    };
  },
  mounted() {},
  mixins: [ErrorMessages],
  methods: {
    ...mapActions(['login']),
    async onClickLogin() {
      try {
        await this.$vs.loading({
          container: '#login',
          scale: 0.45,
        });
        await this.login({
          username: this.email,
          password: this.password,
        });
        this.$vs.loading.close('#login > .con-vs-loading');
        await this.$router.push('/');
      } catch (error) {
        this.$vs.loading.close('#login > .con-vs-loading');
        this.showErrorMessage('Invalid username or password');
      }
    },
  },
};
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
